<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1150px"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => closeCardFunDialog('CardFunEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CardFun"
                    ref="CardFunEditForm"
                    label-width="100px"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                            size="mini"
                        >
                            <el-descriptions-item :contentStyle="{'min-width':'380px','text-align': 'center'}">
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaitupian.png'" height="80px"></div>
                                    <div>卡牌图片</div>
                                </template>
                                <common-upload-component
                                    ref="upload"
                                    :limit="18"
                                    :ffileList="CardFun.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaijianjie.png'" height="80px"></div>
                                    <div>卡牌简介</div>
                                </template>
                                <el-descriptions
                                    direction="vertical"
                                    :column="5"
                                    :labelStyle="{'width':'100px','text-align':'center'}"
                                    :contentStyle="{'min-width':'150px','text-align': 'center'}"
                                    border
                                    size="samll"
                                >
                                    <el-descriptions-item :span="3">
                                        <template slot="label">
                                            <span>卡牌名称</span>
                                        </template>
                                        <el-input v-model="CardFun.name" placeholder="请填写卡牌名称" clearable class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="2">
                                        <template slot="label">
                                            <span>传递方式</span>
                                        </template>
                                        <el-select
                                            v-model="CardFun.send"
                                            filterable
                                            clearable
                                            placeholder=""
                                            class="send_c"
                                            size="small"
                                        >
                                            <el-option
                                                v-for="item in AllSend"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯红</span>
                                        </template>
                                        <el-input v-model="CardFun.red" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯蓝</span>
                                        </template>
                                        <el-input v-model="CardFun.blue" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯黑</span>
                                        </template>
                                        <el-input v-model="CardFun.black" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>红黑</span>
                                        </template>
                                        <el-input v-model="CardFun.r_b" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>蓝黑</span>
                                        </template>
                                        <el-input v-model="CardFun.b_b" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="5">
                                        <template slot="label">
                                            <span>使用时机</span>
                                        </template>
                                        <el-input v-model="CardFun.use" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="5">
                                        <template slot="label">
                                            <span>目标对象</span>
                                        </template>
                                        <el-input v-model="CardFun.target" class="input_name" size="small"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="5">
                                        <template slot="label">
                                            <span>卡牌效果</span>
                                        </template>
                                        <el-input v-model="CardFun.fun" type="textarea" :rows="3" resize="none" size="small"></el-input>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaishuoming.png'" height="80px"></div>
                                    <div>卡牌说明</div>
                                </template>
                                <common-editor-component
                                    ref="kpeditor"
                                    :fcontent="CardFun.note"
                                ></common-editor-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center','font-weight':'bold'}"
                            :contentStyle="{'text-align': 'center','min-width':'260px'}"
                            border
                            size="mini"
                        >
                            <el-descriptions-item :contentStyle="{'min-width':'380px','text-align': 'center'}">
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaitupian.png'" height="80px"></div>
                                    <div>卡牌图片</div>
                                </template>
                                <el-carousel :interval="2000" indicator-position="none" type="card" height="300px">
                                    <el-carousel-item v-for="item in CardFun.picture" :key="item.name">
                                        <img :src="item.url" height="200px" alt="" style="border-radius: 10px;">
                                    </el-carousel-item>
                                </el-carousel>
                                <common-upload-component
                                    ref="upload"
                                    v-show="false"
                                    :disabled="true"
                                    :ffileList="CardFun.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaijianjie.png'" height="80px"></div>
                                    <div>卡牌简介</div>
                                </template>
                                <el-descriptions
                                    direction="vertical"
                                    :column="5"
                                    :labelStyle="{'width':'100px','text-align':'center'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                    
                                >
                                    <el-descriptions-item :span="3">
                                        <template slot="label">
                                            <span>卡牌名称</span>
                                        </template>
                                        <span style="color: #2828FF;font-family: HarmonyOS_Sans_Black;">{{CardFun.name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="2">
                                        <template slot="label">
                                            <span>传递方式</span>
                                        </template>
                                        <el-tag v-if="CardFun.send === 1" size="small" effect="plain">密电</el-tag>
                                        <el-tag v-if="CardFun.send === 2" size="small" effect="plain" type="danger">直达</el-tag>
                                        <el-tag v-if="CardFun.send === 3" size="small" effect="plain" type="warning">文本</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯红</span>
                                        </template>
                                        <span style="color: #CE0000;font-family: HarmonyOS_Sans_Light;">{{ CardFun.red }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯蓝</span>
                                        </template>
                                        <span style="color: #0080FF;font-family: HarmonyOS_Sans_Light;">{{ CardFun.blue }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>纯黑</span>
                                        </template>
                                        <span style="color: #000000;font-family: HarmonyOS_Sans_Light;">{{ CardFun.black }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>红黑</span>
                                        </template>
                                        <span style="color: #FF79BC;font-family: HarmonyOS_Sans_Light;">{{ CardFun.r_b }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>蓝黑</span>
                                        </template>
                                        <span style="color: #009393;font-family: HarmonyOS_Sans_Light;">{{ CardFun.b_b }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=5 :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>使用时机</span>
                                        </template>
                                        <span>{{ CardFun.use }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=5 :contentStyle="{'min-width':'80px','text-align': 'center'}">
                                        <template slot="label">
                                            <span>目标对象</span>
                                        </template>
                                        <span>{{ CardFun.target }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=5 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                        <template slot="label">
                                            <span>卡牌效果</span>
                                        </template>
                                        <span>{{CardFun.fun}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/kapaishuoming.png'" height="80px"></div>
                                    <div>卡牌说明</div>
                                </template>
                                <div class="el-tiptap-editor__content" v-html="CardFun.note"></div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改卡牌</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="CardFunDel('CardFunEditForm')"
                        >删除卡牌</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardFunDialog('CardFunEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CardFunEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('CardFunEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardFunDialog('CardFunEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CardFunEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { CardFunAddRequest,CardFunDelRequest,CardFunEditRequest } from '@/network/card/CardFun.js'

import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'

export default {
    name: 'CardFunEditComponent',
    data() {
        return {
            login_loading: false,
            CardFun: {
                name: '',
                send: '',
                use: '',
                target: '',
                fun: '',
                red: 0,
                blue: 0,
                black: 0,
                r_b: 0,
                b_b: 0,
                note: '',
                picture:[]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '卡牌详情'
            } else if (this.show_type === 1) {
                return '添加卡牌'
            } else {
                return '修改卡牌'
            }
        }
    },
    methods: {
        closeCardFunDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitCardFunDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardFunInsert(formName)
            })
        },
        CardFunInsert(formName) {
            this.login_loading = true
            this.CardFun.picture = this.$refs.upload.fileList
            this.CardFun.note = this.$refs.kpeditor.content
            CardFunAddRequest(this.CardFun)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitCardFunDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            this.CardFun = {
                name: '',
                send: '',
                use: '',
                target: '',
                fun: '',
                red: 0,
                blue: 0,
                black: 0,
                r_b: 0,
                b_b: 0,
                note: '',
                picture:[]
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardFunEdit(formName)
            })
        },
        CardFunEdit(formName) {
            this.login_loading = true
            this.CardFun.id = this.id
            this.CardFun.picture = this.$refs.upload.fileList
            this.CardFun.note = this.$refs.kpeditor.content
            CardFunEditRequest(this.CardFun)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitCardFunDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.CardFun.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CardFunDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    CardFunDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeCardFunDialog(formName))
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        card_fun_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllSend: {
            type: Array,
            default() {
                return []
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonEditorComponent
    },
    watch: {
        card_fun_details_data(newVal) {
            this.CardFun = newVal
        }
    },
}
</script>

<style lang='less' scoped>
.gender_c,.group_c,.hide_c {
    width: 100%
}

.font_family {
    font-size: 50px;
}
</style>