<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="卡牌名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="卡牌名称"
                    @keyup.enter.native="search()"
                    clearable
                    class="name_c"
                ></el-input>
            </el-form-item>
            <el-form-item  label="传递方式" prop="send">
                <el-select
                    v-model="form.send"
                    filterable
                    clearable
                    placeholder=""
                    class="send_c"
                >
                    <el-option
                        v-for="item in AllSend"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(AddAuth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加卡牌</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'CardRoleSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                send:'',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllSend: {
            type: Array,
            default() {
                return []
            },
        },
        AddAuth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 5px;
}
.temp_add {
    float: right;
    margin-bottom: 10px;
}
.name_c {
    width:150px
}
.send_c {
    width:120px
}
</style>