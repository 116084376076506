<template>
    <div>
        <card-fun-search-bar-component
            AddAuth="新增卡牌"
            :AllSend="AllSend"
            @search="get_card_fun_index"
            @addTeam="addTeam"
        ></card-fun-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
                ref="CardFunDataTable"
                @sort-change="sort_change"
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="name"
                    label="卡牌名称"
                    min-width="90px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="send"
                    label="传递方式"
                    min-width="90px"
                    >
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.send === 1" size="small" effect="plain">密电</el-tag>
                        <el-tag v-if="scope.row.send === 2" size="small" effect="plain" type="danger">直达</el-tag>
                        <el-tag v-if="scope.row.send === 3" size="small" effect="plain" type="warning">文本</el-tag>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template v-slot:header>
                        <div style="text-align: center;">一副牌包含的张数</div>
                    </template>
                    <el-table-column
                        align="center"
                        prop="red"
                        min-width="60px"
                        sortable="custom"
                    >
                        <template v-slot:header>
                            <span style="text-align: center;">纯红 <span style="color: #CE0000;font-family: HarmonyOS_Sans_Black;">{{ table_sum.sum_red }}</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #CE0000;font-family: HarmonyOS_Sans_Light;">{{ scope.row.red }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="blue"
                        label="纯蓝"
                        min-width="60px"
                        sortable="custom"
                    >
                        <template v-slot:header>
                            <span style="text-align: center;">纯蓝 <span style="color: #0080FF;font-family: HarmonyOS_Sans_Black;">{{ table_sum.sum_blue }}</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #0080FF;font-family: HarmonyOS_Sans_Light;">{{ scope.row.blue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="black"
                        label="纯黑"
                        min-width="60px"
                        sortable="custom"
                    >
                        <template v-slot:header>
                            <span style="text-align: center;">纯黑 <span style="color: #000000;font-family: HarmonyOS_Sans_Black;">{{ table_sum.sum_black }}</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #000000;font-family: HarmonyOS_Sans_Light;">{{ scope.row.black }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="r_b"
                        label="红黑"
                        min-width="60px"
                        sortable="custom"
                    >
                        <template v-slot:header>
                            <span style="text-align: center;">红黑 <span style="color: #FF79BC;font-family: HarmonyOS_Sans_Black;">{{ table_sum.sum_r_b }}</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #FF79BC;font-family: HarmonyOS_Sans_Light;">{{ scope.row.r_b }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="b_b"
                        label="蓝黑"
                        min-width="60px"
                        sortable="custom"
                    >
                        <template v-slot:header>
                            <span style="text-align: center;">蓝黑 <span style="color: #009393;font-family: HarmonyOS_Sans_Black;">{{ table_sum.sum_b_b }}</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #009393;font-family: HarmonyOS_Sans_Light;">{{ scope.row.b_b }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        min-width="60px"
                        >
                        <template v-slot:header>
                            <div style="text-align: center;font-family: HarmonyOS_Sans_Black;">合计 <span style="color: #9393FF;">{{ table_sum.sum_all }}</span></div>
                        </template>
                        <template slot-scope="scope">
                            <span style="color: #9393FF;font-family: HarmonyOS_Sans_Black;">{{ scope.row | sum_num }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="查看"
                    min-width="70px"
                >
                    <template slot-scope="scope">
                        <el-button @click="details_card_fun(scope.row.id)" size="mini">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            @search="get_card_fun_index"
        ></common-page-component>
        <card-fun-edit-component
            edit_auth="修改卡牌"
            del_auth="删除卡牌"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :card_fun_details_data="fcard_fun_details_data"
            :AllSend="AllSend"
            @exitCardFunDialog="dialogExit"
            @search="get_card_fun_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_card_fun"
        ></card-fun-edit-component>
    </div>
</template>

<script>
import { CardFunIndexRequest,CardFunDetailsRequest } from '@/network/card/CardFun.js'

import CardFunSearchBarComponent from '@/components/card/CardFun/CardFunSearchBarComponent'
import CardFunEditComponent from '@/components/card/CardFun/CardFunEditComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            total: 0,
            cond: {
                name: '',
                send: '',
                page: 1,
                limit: 10,
                order: ''
            },
            table_data: [],
            table_sum:{
                sum_red: 0,
                sum_blue: 0,
                sum_black: 0,
                sum_r_b: 0,
                sum_b_b: 0
            },
            fcard_fun_details_data: {},
            AllSend:[
                {id:1,name:'密电'},
                {id:2,name:'直达'},
                {id:3,name:'文本'}
            ]
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_card_fun_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.send = param.send ?? this.cond.send
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            if (param.order) {
                this.cond.order = param.order
                this.cond.page = 1
            }

            CardFunIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.table_sum = s.result.sum_data
                        this.total = s.result.total
                        
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally()
        },
        details_card_fun(id) {
            this.fid = id
            this.fdialogFormVisible = true
            CardFunDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fcard_fun_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        sort_change(column) {
            const obj = {order: column['order'],prop: column['prop']}
            this.get_card_fun_index({order: obj})
        }
    },
    filters:{
        sum_num(v) {
            return v.red + v.blue +v.black + v.r_b +v.b_b
        }
    },
    props:{},
    created(){
        this.get_card_fun_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        CardFunSearchBarComponent,
        CardFunEditComponent,
        CommonPageComponent
    },
    watch:{},
}
</script>

<style lang='less' scoped></style>