import request from '../request'

//卡牌列表
export const CardFunIndexRequest = p => {
    return request({
        method:'GET',
        url:'CardFun/index',
        params: p
    })
}

//添加卡牌
export const CardFunAddRequest = d => {
    return request({
        method:'POST',
        url:'CardFun/add',
        data: d
    })
}

//删除角卡牌
export const CardFunDelRequest = id => {
    return request({
        method:'DELETE',
        url:'CardFun/del',
        data: {
            id: id
        }
    })
}

//修改卡牌
export const CardFunEditRequest = data => {
    return request({
        method:'PUT',
        url:'CardFun/edit',
        data
    })
}

//获取卡牌详情
export const CardFunDetailsRequest = id => {
    return request({
        method:'GET',
        url:'CardFun/details',
        params: {
            id
        }
    })
}