import { render, staticRenderFns } from "./CardFunEditComponent.vue?vue&type=template&id=0cc86fc0&scoped=true"
import script from "./CardFunEditComponent.vue?vue&type=script&lang=js"
export * from "./CardFunEditComponent.vue?vue&type=script&lang=js"
import style0 from "./CardFunEditComponent.vue?vue&type=style&index=0&id=0cc86fc0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc86fc0",
  null
  
)

export default component.exports